/* Roobert-Regular */
@font-face {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roobert-trial/Roobert-Regular.otf"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roobert-trial/Roobert-Regular.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/roobert-trial/Roobert-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/roobert-trial/Roobert-Regular.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
/* Roobert-SemiBold - latin */
@font-face {
  font-family: "Roobert";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/roobert-trial/Roobert-SemiBold.otf"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roobert-trial/Roobert-SemiBold.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/roobert-trial/Roobert-SemiBold.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/roobert-trial/Roobert-SemiBold.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
